<template>
  <md-card class="md-p-0 md-m-0">
    <md-card-header class="md-p-0 md-m-0">
      <md-toolbar class="md-m-0 md-pt-0 md-pb-0">
        <h5 class="md-title" style="flex: 1">
          {{ feature.properties.name }}
        </h5>
        <md-button
          class="md-icon-button"
          @click="
            () => {
              this.$emit('click', true);
            }
          "
        >
          <md-icon class="md-raised md-accent">
            cancel
          </md-icon>
        </md-button>
      </md-toolbar>
    </md-card-header>

    <md-card-content v-if="actionSelected == 'beds'">
      <table>
        <tbody>
          <tr class="md-table-row">
            <td class="md-table-cell">
              <div class="md-table-cell-container">
                Taxa de ocupação
                {{ feature.properties.percentage }}
              </div>
            </td>
          </tr>
          <tr class="md-table-row">
            <td class="md-table-cell">
              <div class="md-table-cell-container">
                Leitos clínicos: {{ feature.properties.beds }}
              </div>
            </td>
          </tr>
          <tr class="md-table-row">
            <td class="md-table-cell">
              <div class="md-table-cell-container">
                Leitos clínicos ocupados:
                {{ feature.properties.occupied_beds }}
              </div>
            </td>
          </tr>
          <tr class="md-table-row">
            <td class="md-table-cell">
              <div class="md-table-cell-container">
                Leitos clínicos COVID:
                {{ feature.properties.occupied_covids }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </md-card-content>
    <md-card-content v-if="actionSelected == 'uti_beds'">
      <table>
        <tr class="md-table-row">
          <td class="md-table-cell">
            <div class="md-table-cell-container">
              Taxa de ocupação
              {{ feature.properties.percentage }}
            </div>
          </td>
        </tr>
        <tr class="md-table-row">
          <td class="md-table-cell">
            <div class="md-table-cell-container">
              Leitos UTI:
              {{ feature.properties.uti_beds }}
            </div>
          </td>
        </tr>
        <tr class="md-table-row">
          <td class="md-table-cell">
            <div class="md-table-cell-container">
              Leitos UTI ocupados:
              {{ feature.properties.uti_occupied_beds }}
            </div>
          </td>
        </tr>
        <tr class="md-table-row">
          <td class="md-table-cell">
            <div class="md-table-cell-container">
              Leitos UTI COVID:
              {{ feature.properties.uti_occupied_covids }}
            </div>
          </td>
        </tr>
      </table>
    </md-card-content>
    <md-card-content v-if="actionSelected == 'stabilization_beds'">
      <table>
        <tr class="md-table-row">
          <td class="md-table-cell">
            <div class="md-table-cell-container">
              Taxa de ocupação
              {{ feature.properties.percentage }}
            </div>
          </td>
        </tr>
        <tr class="md-table-row">
          <td class="md-table-cell">
            <div class="md-table-cell-container">
              Taxa de ocupação
              {{ feature.properties.percentage }}
            </div>
          </td>
        </tr>
        <tr class="md-table-row">
          <td class="md-table-cell">
            <div class="md-table-cell-container">
              Leitos de estabilização:
              {{ feature.properties.stabilization_beds }}
            </div>
          </td>
        </tr>
        <tr class="md-table-row">
          <td class="md-table-cell">
            <div class="md-table-cell-container">
              Leitos de estabilização ocupados:
              {{ feature.properties.stabilization_occupied_beds }}
            </div>
          </td>
        </tr>
        <tr class="md-table-row">
          <td class="md-table-cell">
            <div class="md-table-cell-container">
              Leitos de estabilização COVID:
              {{ feature.properties.stabilization_occupied_covids }}
            </div>
          </td>
        </tr>
      </table>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  props: {
    actionSelected: {
      type: String,
      required: true
    },
    feature: Object
  },
  mounted() {
    console.log(this.feature);
  }
};
</script>

<style></style>
