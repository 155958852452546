<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <stats-card header-color="indigo">
          <template slot="header">
            <div class="card-icon" @click="menuVisible = !menuVisible">
              <md-icon v-if="!load">coronavirus</md-icon>
              <i class="md-icon md-icon-font md-theme-default" v-if="load">
                <md-progress-spinner
                  :md-diameter="30"
                  :md-stroke="3"
                  md-mode="indeterminate"
                ></md-progress-spinner>
              </i>
            </div>
          </template>
          <template slot="content">
            <md-app md-mode="reveal">
              <md-app-toolbar class="md-primary" style="padding: 4px;">
                <div class="md-toolbar-section-start">
                  <md-datepicker
                    v-model="selectedDate"
                    :md-disabled-dates="disabledDates"
                  />
                </div>

                <div class="md-toolbar-section-end">
                  <md-button class="md-raised md-white" @click="downloadMap">
                    Download PNG
                  </md-button>
                  <a id="image-download" download="map.png"></a>
                </div>
              </md-app-toolbar>

              <md-app-drawer
                :md-active.sync="menuVisible"
                md-right
                md-swipeable
                md-permanent="clipped"
              >
                <div class="full-control">
                  <div class="list">
                    <md-table v-model="actions" md-card>
                      <md-table-row
                        slot="md-table-row"
                        slot-scope="{ item }"
                        md-selectable="single"
                      >
                        <md-table-cell md-label="Ação" md-sort-by="action">
                          <md-switch
                            v-model="actionSelected"
                            :value="item.action"
                            @change="generateMap"
                          ></md-switch>
                        </md-table-cell>
                        <md-table-cell md-label="Opção" md-sort-by="text">{{
                          item.text
                        }}</md-table-cell>
                      </md-table-row>
                    </md-table>
                  </div>
                </div>
              </md-app-drawer>

              <md-app-content>
                <vl-map
                  :load-tiles-while-animating="true"
                  :load-tiles-while-interacting="true"
                  ref="map"
                  style="height: 800px"
                  @postcompose="() => {}"
                >
                  <vl-view
                    :zoom.sync="zoom"
                    :center.sync="center"
                    :rotation.sync="rotation"
                  ></vl-view>

                  <vl-layer-tile id="osm">
                    <vl-source-osm></vl-source-osm>
                  </vl-layer-tile>

                  <vl-layer-vector>
                    <vl-source-vector
                      ref="layerRegions"
                      :features.sync="features"
                    >
                    </vl-source-vector>
                    <vl-style-box>
                      <vl-style-stroke
                        color="rgba(255,255,255,0.5)"
                        :width="1"
                      ></vl-style-stroke>
                    </vl-style-box>
                  </vl-layer-vector>

                  <vl-layer-vector>
                    <vl-source-vector
                      ref="featureOverlay"
                      :features.sync="featureOverlay"
                    >
                    </vl-source-vector>
                    <vl-style-box>
                      <vl-style-stroke
                        color="rgba(255,255,255,0.5)"
                        :width="3"
                      ></vl-style-stroke>
                      <vl-style-fill
                        color="rgba(255,255,255,0.3)"
                      ></vl-style-fill>
                    </vl-style-box>
                  </vl-layer-vector>

                  <vl-layer-vector>
                    <vl-source-vector
                      ref="pointHealthUnit"
                      :features.sync="pointHealthUnit"
                    >
                    </vl-source-vector>
                    <vl-style-box>
                      <vl-style-icon
                        :src="marker_health_unit"
                        :scale="0.8"
                        :anchor="[0.5, 1]"
                      ></vl-style-icon>
                    </vl-style-box>
                  </vl-layer-vector>

                  <!-- interactions -->
                  <vl-interaction-select :features.sync="selectedFeatures">
                    <template slot-scope="selection">
                      <vl-layer-vector>
                        <vl-feature
                          v-for="item in selection.features"
                          :key="item.id"
                          :id="`polygon-overlay-${item.id}`"
                          :properties="item.properties"
                        >
                          <template slot-scope="feature">
                            <vl-geom-polygon
                              :coordinates="item.geometry.coordinates"
                            >
                              {{ feature }}
                            </vl-geom-polygon>
                            <vl-style-box>
                              <vl-style-stroke
                                color="rgba(255,255,255,0.1)"
                                :width="3"
                              ></vl-style-stroke>
                              <vl-style-fill
                                color="rgba(255,255,255,0.1)"
                              ></vl-style-fill>
                            </vl-style-box>
                          </template>
                        </vl-feature>
                      </vl-layer-vector>
                      <!-- select styles -->
                      <!--// select styles -->

                      <!-- selected feature popup -->
                      <vl-overlay
                        v-for="feature in selection.features"
                        :key="feature.id"
                        :id="feature.id"
                        class="feature-popup"
                        :position="pointOnSurface(feature.geometry)"
                        :auto-pan="true"
                        :auto-pan-animation="{ duration: 300 }"
                      >
                        <template>
                          <OverlayCard
                            :feature="feature"
                            :actionSelected="actionSelected"
                            @click="
                              selectedFeatures = selectedFeatures.filter(
                                f => f.id !== feature.id
                              )
                            "
                          ></OverlayCard>
                        </template>
                      </vl-overlay>
                      <!--// selected popup -->
                    </template>
                  </vl-interaction-select>

                  <div class="layer-legend ol-viewport">
                    <div class="list">
                      <md-list :md-expand-single="true">
                        <md-list-item
                          md-expand
                          :md-expanded="true"
                          class="md-p-0"
                        >
                          <md-icon>info</md-icon>
                          <span class="md-list-item-text">Ocupação</span>

                          <md-list slot="md-expand">
                            <md-list-item class="md-m-0 md-p-0">
                              <md-icon
                                :style="`color: ${this.$colorStage.green}`"
                                >map</md-icon
                              >
                              <span class="md-list-item-text">{{
                                criteria[0]
                              }}</span>
                            </md-list-item>
                            <md-list-item class="md-m-0 md-p-0">
                              <md-icon
                                :style="`color: ${this.$colorStage.yellow}`"
                                >map</md-icon
                              >
                              <span class="md-list-item-text">{{
                                criteria[1]
                              }}</span>
                            </md-list-item>
                            <md-list-item class="md-m-0 md-p-0">
                              <md-icon :style="`color: ${this.$colorStage.red}`"
                                >map</md-icon
                              >
                              <span class="md-list-item-text">{{
                                criteria[2]
                              }}</span>
                            </md-list-item>
                            <md-list-item class="md-m-0 md-p-0">
                              <md-icon
                                :style="`color: ${this.$colorStage.purple}`"
                                >map</md-icon
                              >
                              <span class="md-list-item-text">{{
                                criteria[3]
                              }}</span>
                            </md-list-item>
                          </md-list>
                        </md-list-item>
                      </md-list>
                    </div>
                  </div>
                </vl-map>
              </md-app-content>
            </md-app>
          </template>

          <template slot="footer">
            <div class="stats"></div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment-mini";
import { kebabCase } from "lodash";
import { StatsCard } from "@/components";
import hexToRgba from "hex-to-rgba";
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader
import { findPointOnSurface, createStyle } from "vuelayers/lib/ol-ext";
import { Fill, Stroke, Style, Text } from "ol/style";
import OverlayCard from "./OverlayCard";
//import { Vector as VectorSource } from "ol/source";
//import { Vector as VectorLayer } from "ol/layer";
//import Feature from "ol/Feature";
//import Point from "ol/geom/Point";

Vue.use(VueLayers, {
  dataProjection: "EPSG:4326"
});

const easeInOut = t => 1 - Math.pow(1 - t, 3);

export default {
  components: {
    StatsCard,
    OverlayCard
  },
  props: {
    marker_health_unit: {
      type: String,
      default: require("@/assets/img/health_unit.png")
    },
    flag: {
      type: String,
      default: require("@/assets/img/health-medical.png")
    },
    sourceProp: {
      type: String,
      default: null
    },
    datasetNameProp: {
      type: String,
      default: null
    },
    typeProp: {
      type: String,
      default: null
    }
  },
  async created() {
    this.load = true;
    if (this.sourceProp == null) {
      this.source = this.$route.params.source;
    } else {
      this.source = this.sourceProp;
    }

    if (this.datasetNameProp == null) {
      this.datasetName = this.$route.params.datasetName;
    } else {
      this.datasetName = this.datasetNameProp;
    }

    if (this.typeProp == null) {
      this.type = this.$route.params.type;
    } else {
      this.type = this.typeProp;
    }

    this.highlightStyle = new Style({
      stroke: new Stroke({ color: "rgba(51,105,30, 0.1)", width: 1 }),
      fill: new Fill({ color: "rgba(51,105,30, 0.4)" }),
      text: new Text({
        font: "12px Calibri,sans-serif",
        fill: new Fill({ color: "#000" }),
        stroke: new Stroke({ color: "#fff", width: 3 })
      })
    });

    this.getDataSets();
  },
  async mounted() {
    this.load = true;

    let type = "";
    let filter = "";
    let datasetName = "";

    if (this.type == "region") {
      type = "Regiões";
      this.zoom = 4.8;
      this.center = [-48.0073978, -15.721751];
    } else if (this.type == "state") {
      type = "Estados";
      this.zoom = 7.1;
      this.center = [-43.0691055, -6.8420932];
    } else if (this.type == "city") {
      type = "Cidades";
      this.zoom = 7.1;
      this.center = [-43.0691055, -6.8420932];
    } else if (this.type == "mesoregion") {
      type = "Mesoregiões";
      this.zoom = 7.1;
      this.center = [-43.0691055, -6.8420932];
    } else if (this.type == "microregion") {
      type = "Microrregiões";
      this.zoom = 7.1;
      this.center = [-43.0691055, -6.8420932];
    } else if (this.type == "mesoregion_sesapi") {
      type = "Macro Região";
      this.zoom = 7.1;
      this.center = [-43.0691055, -6.8420932];
    } else if (this.type == "microregion_sesapi") {
      type = "Regionais";
      this.zoom = 7.1;
      this.center = [-43.0691055, -6.8420932];
    } else if (this.type == "microregion_sesapi_covid") {
      type = "Regionais COVID";
      this.zoom = 7.1;
      this.center = [-43.0691055, -6.8420932];
    }

    if (this.datasetName == "occupation_of_health_units") {
      datasetName = "hospitalização";
    }

    this.title = `Mapa de ${datasetName} ${filter} por ${type} Fonte: ${this.source.toUpperCase()}`;

    this.actionSelected = "beds";
    this.load = true;

    this.load = false;
    await this.getLayerMap();
    await this.setEventMap();
    await this.welcomeMessage();

    this.selectedDate = await (() => {
      return this.datasets[0].dates[0].date;
    })();

    this.generateMap();
    this.$watch("selectedDate", () => {
      this.generateMap();
    });

    //await this.setEventMap();
  },
  data() {
    return {
      selectedDate: null,
      disabledDates: date => {
        let formatDate = moment(date).format("YYYY-MM-DD");
        let year = moment(date).format("YYYY");
        let disabled = true;

        this.datasets.forEach(dataset => {
          if (dataset.year == year) {
            dataset.dates.forEach(item => {
              if (item.date == formatDate) {
                disabled = false;
              }
            });
          }
        });

        return disabled;
      },
      map: null,
      zoom: 4.8,
      center: [-48.0073978, -15.721751],
      rotation: 0,
      features: [],
      selectedFeatures: [],
      clusterSource: null,
      clusters: null,
      actionSelected: null,
      datasetsSelected: null,
      datasets: [],
      year: null,
      occupiedDates: [],
      colorCases: null,
      menuVisible: true,
      load: false,
      casesColor: null,
      title: "",
      image: null,
      style: null,
      highlightStyle: null,
      featureOverlay: [],
      vectorSource: null,
      vectorLayer: null,
      pixel: null,
      featureSelected: null,
      markerPopup: null,
      popup: {
        container: null,
        content: null,
        closer: null
      },
      legend: {
        div: null,
        table: null
      },
      actions: [
        {
          action: "beds",
          text: "Leitos clínicos"
        },
        {
          action: "uti_beds",
          text: "Leitos de UTI"
        },
        {
          action: "stabilization_beds",
          text: "Leitos de Estabilização"
        }
      ],
      fillGray: new Fill({ color: "rgba(255,255,255,0.3)" }),
      fillGreen: new Fill({ color: hexToRgba(this.$colorStage.green, 0.7) }),
      fillYellow: new Fill({ color: hexToRgba(this.$colorStage.yellow, 0.7) }),
      fillRed: new Fill({ color: hexToRgba(this.$colorStage.red, 0.7) }),
      fillPurple: new Fill({ color: hexToRgba(this.$colorStage.purple, 0.7) }),
      pointHealthUnit: [],
      criteria: ["< 50%", ">= 50% e < 75%", ">= 75% e < 94%", ">= 94%"]
    };
  },
  methods: {
    pointOnSurface: findPointOnSurface,
    geometryTypeToCmpName(type) {
      return "vl-geom-" + kebabCase(type);
    },
    selectFilter(feature) {
      return ["position-feature"].indexOf(feature.getId()) === -1;
    },
    welcomeMessage() {
      this.$emit("welcomeMessage", {
        title: this.title
      });
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    async getDataSets() {
      try {
        let result = await Vue.$http.get(
          `v1/dataset/${this.source}/${this.datasetName}?limit=5`
        );
        this.datasets = await result.data;
        this.year = await this.datasets[0].year;
        this.datasetsSelected = await this.datasets[0];
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    styleFunction(feature) {
      this.style.getText().setText(feature.get("name"));
      return this.style;
    },
    async getLayerMap() {
      try {
        let result = await Vue.$http.get(`v1/${this.type}?type=map`);
        let regions = result.data;
        await regions.forEach(region => {
          this.features.push({
            type: "Feature",
            id: region.id,
            geometry: JSON.parse(region.geoJson),
            properties: {
              name: region.name,
              health_units: []
            }
          });
        });

        if (
          this.$refs.layerRegions !== undefined &&
          this.$refs.layerRegions.$source !== undefined
        ) {
          await this.$refs.layerRegions.$source.forEachFeature(feature => {
            feature.setStyle(
              new Style({
                stroke: new Stroke({ color: "#cccccc", width: 1 }),
                fill: new Fill({
                  color: "rgba(217,217,217, 0.5)"
                })
              })
            );
            feature.getStyle().setText(
              new Text({
                text: feature.getProperties().name,
                font: "12px Calibri,sans-serif",
                fill: new Fill({ color: "#000" }),
                stroke: new Stroke({ color: "#fff", width: 4 })
              })
            );
          });
        }
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    downloadMap() {
      this.$refs.map.$map.once("rendercomplete", () => {
        let mapCanvas = document.createElement("canvas");
        let size = this.$refs.map.$map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        let mapContext = mapCanvas.getContext("2d");

        Array.prototype.forEach.call(
          document.querySelectorAll(".ol-viewport canvas"),
          canvas => {
            if (canvas.width > 0) {
              let opacity = canvas.parentNode.style.opacity;
              mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(mapCanvas.msToBlob(), "map.png");
        } else {
          let link = document.getElementById("image-download");
          link.href = mapCanvas.toDataURL();
          link.click();
        }
      });
      this.$refs.map.$map.renderSync();
    },

    async generateMap() {
      if (this.selectedDate != null && this.actionSelected) {
        this.load = true;
        let date = moment(this.selectedDate).format("YYYY-MM-DD");
        let year = moment(this.selectedDate).format("YYYY");
        try {
          if (this.occupiedDates[date] == null) {
            let result = await Vue.$http.get(
              `v1/${this.type}/${this.source}/${this.datasetName}/${year}?type=map&filter_map=occupation&date=${date}`
            );
            this.occupiedDates[date] = result.data;
          }

          this.createMarkers(this.occupiedDates[date]);

          this.coloringMap(this.occupiedDates[date]);

          //this.load = false;
        } catch (e) {
          await this.$store.dispatch("alerts/error", e);
          this.load = false;
        }
        return true;
      }
    },
    async coloringMap(regions) {
      regions.forEach(async region => {
        let bedsPercentage = this.calcPercentage(region);
        let percentage = bedsPercentage.percentage;
        let feature;

        feature = this.features.find(feature => {
          return feature.id == region.id;
        });

        feature.properties = Object.assign(feature.properties, {
          value: `${percentage.toLocaleString("pt-BR")}%`,
          beds: region.occupation.beds,
          occupied_beds: region.occupation.occupied_beds,
          occupied_covids: region.occupation.occupied_covids,
          uti_beds: region.occupation.uti_beds,
          uti_occupied_beds: region.occupation.uti_occupied_beds,
          uti_occupied_covids: region.occupation.uti_occupied_covids,
          stabilization_beds: region.occupation.stabilization_beds,
          stabilization_occupied_beds:
            region.occupation.stabilization_occupied_beds,
          stabilization_occupied_covids:
            region.occupation.stabilization_occupied_covids,
          health_units: region.occupation.health_units,
          percentage: `${percentage.toLocaleString("pt-BR")}%`
        });

        if (
          this.$refs.layerRegions !== undefined &&
          this.$refs.layerRegions.$source !== undefined
        ) {
          feature = this.$refs.layerRegions.$source.getFeatureById(region.id);
          let fill = this.fillGray;

          if (bedsPercentage.beds > 0) {
            if (percentage >= 0 && percentage < 50) {
              //feature.properties.stroke = this.colorStage.green;
              fill = this.fillGreen;
            } else if (percentage >= 50 && percentage < 75) {
              //feature.properties.stroke = this.colorStage.yellow;
              fill = this.fillYellow;
            } else if (percentage >= 75 && percentage < 94) {
              //feature.properties.stroke = this.colorStage.red;
              fill = this.fillRed;
            } else if (percentage >= 94) {
              //feature.properties.stroke = this.colorStage.purple;
              //feature.properties.fill = hexToRgba(this.colorStage.purple, 0.7);
              fill = this.fillPurple;
            }
          }

          feature.setStyle(
            new Style({
              fill: fill,
              text: new Text({
                text: feature.getProperties().name,
                font: "12px Calibri,sans-serif",
                fill: new Fill({ color: "#000" }),
                stroke: new Stroke({ color: "#fff", width: 4 })
              })
            })
          );
        }
      });

      //this.load = false;
    },
    calcPercentage(region) {
      if (this.actionSelected == "beds") {
        if (region.occupation.occupied_beds > 0 && region.occupation.beds > 0) {
          let percentage =
            region.occupation.occupied_beds / (region.occupation.beds / 100);
          return { percentage: percentage, beds: region.occupation.beds };
        } else {
          return { percentage: 0, beds: region.occupation.beds };
        }
      }

      if (this.actionSelected == "uti_beds") {
        if (
          region.occupation.uti_occupied_beds > 0 &&
          region.occupation.uti_beds > 0
        ) {
          let percentage =
            region.occupation.uti_occupied_beds /
            (region.occupation.uti_beds / 100);
          return { percentage: percentage, beds: region.occupation.uti_beds };
        } else {
          return { percentage: 0, beds: region.occupation.uti_beds };
        }
      }

      if (this.actionSelected == "stabilization_beds") {
        if (
          region.occupation.stabilization_occupied_beds > 0 &&
          region.occupation.stabilization_beds > 0
        ) {
          let percentage =
            region.occupation.stabilization_occupied_beds /
            (region.occupation.stabilization_beds / 100);
          return {
            percentage: percentage,
            beds: region.occupation.stabilization_beds
          };
        } else {
          return {
            percentage: 0,
            beds: region.occupation.stabilization_beds
          };
        }
      }
    },
    calcPercentageUnit(region) {
      if (this.actionSelected == "beds") {
        if (region.occupied_beds > 0 && region.beds > 0) {
          return region.occupied_beds / (region.beds / 100);
        } else {
          return 0;
        }
      }

      if (this.actionSelected == "uti_beds") {
        if (region.uti_occupied_beds > 0 && region.uti_beds > 0) {
          return region.uti_occupied_beds / (region.uti_beds / 100);
        } else {
          return 0;
        }
      }

      if (this.actionSelected == "stabilization_beds") {
        if (
          region.stabilization_occupied_beds > 0 &&
          region.stabilization_beds > 0
        ) {
          return (
            region.stabilization_occupied_beds /
            (region.stabilization_beds / 100)
          );
        } else {
          return 0;
        }
      }
    },
    onMapPostCompose({ vectorContext, frameState }) {
      this.features.forEach(feature => {
        feature.properties.health_units.forEach(health_unit => {
          let feature = this.$refs[`marker-${health_unit.id}`][0].$feature;
          if (!feature.getGeometry() || !feature.getStyle()) return;
          let flashGeom = feature.getGeometry().clone();
          let duration = feature.get("duration");
          let elapsed = frameState.time - feature.get("start");
          let elapsedRatio = elapsed / duration;
          let radius = easeInOut(elapsedRatio) * 35 + 5;
          let opacity = easeInOut(1 - elapsedRatio);
          let fillOpacity = easeInOut(0.5 - elapsedRatio);
          vectorContext.setStyle(
            createStyle({
              imageRadius: radius,
              fillColor: [119, 170, 203, fillOpacity],
              strokeColor: [119, 170, 203, opacity],
              strokeWidth: 2 + opacity
            })
          );
          vectorContext.drawGeometry(flashGeom);
          vectorContext.setStyle(feature.getStyle()(feature)[0]);
          vectorContext.drawGeometry(feature.getGeometry());
          if (elapsed > duration) {
            feature.set("start", Date.now());
          }
          this.$refs.map.$map.render();
        });
      });
    },
    async setEventMap() {
      this.$refs.map.$map.on("pointermove", evt => {
        this.$refs.layerRegions.$sourceContainer.$layersContainer.forEachFeatureAtPixel(
          evt.pixel,
          f => {
            let newFeature = this.features.find(feature => {
              return feature.id == f.getId();
            });
            if (newFeature !== undefined) {
              this.featureOverlay = [];
              this.featureOverlay.push(newFeature);
            }
            return true;
          }
        );
      });
    },
    async createMarkers(regions) {
      if (
        this.$refs.pointHealthUnit !== undefined &&
        this.$refs.pointHealthUnit.$source !== undefined
      ) {
        this.pointHealthUnit = [];
        await regions.forEach(async (region, index, array) => {
          await region.occupation.health_units.forEach(async health_unit => {
            let percentage = this.calcPercentageUnit(health_unit);
            if (health_unit.latitude != null && health_unit.longitude != null) {
              this.pointHealthUnit.push({
                type: "Feature",
                id: health_unit.id,
                properties: {
                  cnes_code: health_unit.cnes_code,
                  name: health_unit.alias_company_name,
                  beds: health_unit.beds,
                  occupied_beds: health_unit.occupied_beds,
                  occupied_covids: health_unit.occupied_covids,
                  uti_beds: health_unit.uti_beds,
                  uti_occupied_beds: health_unit.uti_occupied_beds,
                  uti_occupied_covids: health_unit.uti_occupied_covids,
                  stabilization_beds: health_unit.stabilization_beds,
                  stabilization_occupied_beds:
                    health_unit.stabilization_occupied_beds,
                  stabilization_occupied_covids:
                    health_unit.stabilization_occupied_covids,
                  percentage: `${percentage.toLocaleString("pt-BR")}%`
                },
                geometry: {
                  type: "Point",
                  coordinates: [
                    Number(health_unit.longitude),
                    Number(health_unit.latitude)
                  ]
                }
              });
            }
          });
          if (index + 1 === array.length) {
            this.load = false;
          }
        });
        /** 
        await this.$refs.pointHealthUnit.$source.forEachFeature(feature => {
          let free_beds = 1000;
          if (this.actionSelected == "beds") {
            free_beds =
              feature.getProperties().beds -
              feature.getProperties().occupied_beds;
          }

          if (this.actionSelected == "uti_beds") {
            free_beds =
              feature.getProperties().uti_beds -
              feature.getProperties().uti_occupied_beds;
          }

          if (this.actionSelected == "stabilization_beds") {
            free_beds =
              feature.getProperties().stabilization_beds -
              feature.getProperties().stabilization_occupied_beds;
          }

          feature.setStyle(
            new Style({
              image: new Icon({
                anchor: [0.53, 40],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                src: this.marker_health_unit
              }),
              text: new Text({
                text: free_beds.toString(),
                font: "12px Calibri,sans-serif",
                fill: new Fill({ color: "#000" }),
                stroke: new Stroke({ color: "#fff", width: 3 })
              }),
              zIndex: 1000
            })
          );
          console.log(feature.getStyle(), free_beds);
        });
        */
      }
    }
  },
  watch: {
    selectedDate: function() {
      //this.generateMap();
    }
  }
};
</script>
<style scoped lang="scss">
#map {
  width: 100%;
  height: 800px;
}
.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
}

.full-control {
  margin: 10px;
}

ul div.md-card {
  margin: 0;
}

.md-checkbox {
  margin: 0 16px !important;
}

.container-checkbox {
  background-color: #ffffff;
}

.md-table.md-theme-default .md-table-head {
  padding: 5px;
  margin: 5px;
}

.md-table.md-theme-default .md-table-head:first-child {
  max-width: 35px;
  padding: 0;
  margin: 5px;
  text-align: left;
}

.md-table.md-theme-default .md-table-row td {
  max-width: 35px;
  padding: 0;
  margin: 5px;
  text-align: left;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 180px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 1px;
}
.ol-popup-closer:after {
  content: "✖";
}

@media only screen and (max-width: 959px) {
  .ol-legend {
    display: none !important;
  }
}

.ol-legend {
  margin-right: 10px !important;
  margin-top: 10px !important;
  margin-left: auto !important;
  padding: 0;
  width: 124px;
  font-size: 14px;
  text-shadow: 0 0 20px #fff, 0 -10px 20px #fff, 0 10px 20px #fff,
    -20px 0 40px #fff, 20px 0 40px #fff !important;
  font-weight: 600;
}

.md-field .md-input,
.md-field .md-textarea {
  -webkit-text-fill-color: #fff !important;
}

.md-list-item-content {
  padding-left: 15px !important;
}

.layer-legend {
  position: absolute;
  top: 10px;
  left: calc(100% - 445px);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  .list {
    .md-list {
      .md-list-item {
        background: rgba(256, 256, 256, 0.7);
        padding-left: 15px;
      }
    }
  }
}
.md-icon {
  margin-right: 5px !important;
}
</style>
